@font-face {
  font-family: "Montserrat-Light";
  src: url("./assets/fonts/Montserrat-Light.eot");
  src: url("./assets/fonts/Montserrat-Light.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Montserrat-Light.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-Light.woff") format("woff"),
    url("./assets/fonts/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-Bold";
  src: url("./assets/fonts/Montserrat-Bold.eot");
  src: url("./assets/fonts/Montserrat-Bold.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Montserrat-Bold.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-Bold.woff") format("woff"),
    url("./assets/fonts/Montserrat-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat-Medium";
  src: url("./assets/fonts/Montserrat-Medium.eot");
  src: url("./assets/fonts/Montserrat-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./assets/fonts/Montserrat-Medium.woff2") format("woff2"),
    url("./assets/fonts/Montserrat-Medium.woff") format("woff"),
    url("./assets/fonts/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}
